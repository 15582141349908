<template>
  <div v-if="afterApi">
    <div class="page-header custom-container">
      <img class="img-title" src="@/assets/header_member.svg" alt />
      <span>個人檔案</span>
    </div>
    <div class="member custom-container-xl">
      <div class="member__box">
        <span class="member__birth">{{ myData.BirthDay }}</span>
        <div class="member__photo">
          <div class="photo__inset">
            <div class="photo__bg">
              <v-avatar size="168" class="photo">
                <img :src="headImage" />
              </v-avatar>
            </div>
          </div>
          <div class="member__photo__platform">
            <img
              v-show="loginMethods == 'Line'"
              src="@/assets/歸戶平台/LINE.png"
              alt
            />
            <img
              v-show="loginMethods === 'Facebook'"
              src="@/assets/歸戶平台/facebook.png"
              alt
            />
            <img
              v-show="loginMethods === 'Google'"
              src="@/assets/歸戶平台/GOOGLE.png"
              alt
            />
          </div>
        </div>
        <div class="member__space"></div>
        <div class="member__name">
          <div class="member__name__text">{{ myData.Name }}</div>
          <div class="member__name__uid">
            ID：{{ UID }}
            <img src="@/assets/icon-copy.svg" @click="copyClipboard(UID)" />
          </div>
        </div>
        <div class="member__info">
          <div class="info__list">
            <div class="info__item">
              <img src="@/assets/icon_gender.svg" />
              <span>{{ myData.GenderDisplay }}</span>
            </div>
            <div class="info__item">
              <img src="@/assets/icon_location.svg" />
              <span>{{ myData.City }}</span>
            </div>
            <div class="info__item">
              <img src="@/assets/icon_time.svg" />
              <span>{{ myData.BirthTime }}</span>
            </div>
          </div>
          <!-- <div class="info__email">
            <span v-if="email">{{ email }}</span>
            <span v-else>無綁定信箱</span>
          </div>-->
          <div class="info__edit" @click="toMemberEdit()">
            <img class="ifNotActive" src="@/assets/icon_edit.svg" />
            <img class="ifActive" src="@/assets/icon_edit_active.svg" />
            編輯
          </div>
        </div>
      </div>
    </div>
    <!-- 提示框 兩秒消失 -->
    <transition name="fade">
      <div class="toast" v-if="showEdit">該筆資料已修改</div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myData: null,
      afterApi: false,
      showEdit: false,
      email: "",
      UID: "",
    };
  },
  computed: {
    headImage() {
      return localStorage.imageUrl || this.myData.ProfilePhotoSrc;
    },
    loginMethods() {
      return localStorage.loginMethods;
    },
  },
  async mounted() {
    //更新資料
    if (window.localStorage.getItem("friendState") === "Edit") {
      this.showEdit = true;
      setTimeout(() => {
        this.showEdit = false;
        window.localStorage.removeItem("friendState");
      }, 2000);
    }

    if (window.localStorage.getItem("login_status")) {
      this.$API.Get_MemberInfo().then(async (data) => {
        this.email = data.Data.Email;
        window.localStorage["loginIndex"] = data.Data.MemberId;
        this.UID = data.Data.MemberId;
        let self = this.$store.getters.SELF_OBJ;
        const res = await this.$API.Get_AstrolabeFile(self.AstrolabeFileId);
        this.myData = res.Data;

        //6.5新增前端狀態keep效果
        this.$store.commit("updateMyData", this.myData);
        this.myData.GenderDisplay =
          this.myData.Gender === "M" || this.myData.Gender === "男"
            ? "男生"
            : "女生";
        this.myData.City = this.myData.BirthCity;
        this.afterApi = true;
      });
    }
  },
  methods: {
    toMemberEdit() {
      window.localStorage.setItem("editMe", this.myData.AstrolabeFileId);
      window.localStorage.setItem("toWhere", "member");
      this.$store.state.myDataBeEdit = this.myData;
      this.$router.push({ path: "/member-edit" });
    },
    async copyClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.$store.commit("update_toast", ["已成功複製帳號 ID", 2000]);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/header.scss";
@import "@/scss/toast.scss";

.member {
  width: 100%;
  height: auto;
  box-shadow: inset 8px 8px 15px 0 rgba(0, 0, 0, 0.08), 10px 10px 10px 0 #ffffff;
  background-color: $theme-color-3;
  border-radius: 150px 150px 0 0;
  padding-bottom: 44px;

  @include align(center, flex-start);

  @include pad {
    // height: 60vh;
    background-color: rgba($color: $secondary-color-2, $alpha: 0.25);
    box-shadow: none;
    border-radius: 100px 100px 0 0;
  }

  .member__box {
    position: relative;
    @include align(flex-start, center);
    flex-direction: column;
    position: relative;

    @include laptop {
      width: 100%;
    }

    .member__birth {
      position: absolute;
      font-size: 18px;
      color: rgba($color: $secondary-color-3, $alpha: 0.4);
      top: 32px;
      right: 0;

      @include pad {
        position: relative;
        top: 0;
        margin: 16px 0 30px;
      }
      @include mobile {
        font-size: 12px;
      }
    }
    .member__photo {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translate(-50%, 0);
      @include rect(192px, 192px, $theme-color-3, 50%);
      @include center;

      @include pad {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(-0%, 0);
      }
      @include mobile {
        width: 160px;
        height: 160px;
      }
      &__platform {
        position: absolute;
        right: 5px;
        bottom: 8px;
        img {
          width: 45px;
          height: 45px;
          @include mobile {
            width: 38px;
            height: 38px;
          }
        }
      }
      .photo__inset {
        @include center;
        @include rect-inset(170px, 170px, $theme-color-3, 50%);
        @include mobile {
          width: 135px;
          height: 135px;
        }
        .photo__bg {
          position: relative;
          @include center;
          @include rect(152px, 152px, $theme-color-3, 50%);
          @include mobile {
            width: 120px;
            height: 120px;
            .photo {
              width: 140px !important;
              height: 140px !important;
            }
          }
        }
      }
    }

    .member__space {
      width: 208px;
      height: 180px;
      @include pad {
        display: none;
      }
    }
    .member__name {
      padding: 8px 10px;
      margin-top: 32px;
      @include rect(auto, auto, $theme-color-3, 15px);
      @include center;
      flex-direction: column;
      min-height: 48px;
      min-width: 256px;
      max-width: 884px;
      @include pad {
        max-width: 95%;
      }
      @include mobile {
        padding: 14px 10px;
        min-width: 192px;
        min-height: 32px;
        border-radius: 8px;
      }
      &__text {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.8;
        letter-spacing: 1.6px;
        color: $secondary-color-3;
        word-break: break-word;
        @include mobile {
          font-size: 16px;
        }
      }
      &__uid {
        margin-top: 4px;
        font-size: 14px;
        line-height: 28px;
        color: #9b9b9b;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile {
          font-size: 12px;
          line-height: 28px;
        }
        img {
          width: 24px;
          margin-left: 8px;
          cursor: pointer;
          @include mobile {
            width: 20px;
            margin-left: 6px;
          }
        }
      }
    }

    .member__info {
      margin-top: 48px;
      margin-bottom: 44px;
      @include rect(884px, 380px, $theme-color-3, 40px);
      max-width: 884px;
      @include center;
      flex-direction: column;

      @include laptop {
        width: 100%;
      }
      @include pad {
        width: 95%;
        padding: 50px 16px 32px;
        height: auto;
        margin-top: 32px;
        margin-bottom: 24px;
      }
      .info__email {
        margin-top: 20px;
        font-size: 20px;
        line-height: 30px;
        color: #9b9b9b;
        text-decoration: underline;
        @include mobile {
          margin-top: 20px;
          font-size: 14px;
        }
      }
      .info__list {
        @include center;
        width: 770px;
        @include laptop {
          width: 100%;
        }

        .info__item {
          @include center;
          flex-direction: column;
          border-right: 1px solid rgba($color: $secondary-color-3, $alpha: 0.3);
          flex: 1;

          &:last-child {
            border-right: 0;
          }
          img {
            width: 64px;
            height: 64px;
            @include mobile {
              width: 30px;
              height: 30px;
            }
          }
          span {
            margin-top: 8px;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.8;
            letter-spacing: 1.6px;
            color: $secondary-color-3;
            @include mobile {
              font-size: 16px;
            }
          }
        }
      }
      .info__edit {
        @include center;
        @include btn-default(352px, 72px, 24px);
        margin-top: 20px;
        img {
          margin-right: 10px;
        }
        .ifNotActive {
          display: block;
        }
        .ifActive {
          display: none;
        }
        &:active {
          .ifNotActive {
            display: none;
          }
          .ifActive {
            display: block;
          }
        }
        @include mobile {
          width: 80%;
          height: 40px;
          font-size: 14px;
          margin-top: 40px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
footer {
  margin-top: 0;
}
</style>
