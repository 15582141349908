var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.afterApi
    ? _c(
        "div",
        [
          _vm._m(0),
          _c("div", { staticClass: "member custom-container-xl" }, [
            _c("div", { staticClass: "member__box" }, [
              _c("span", { staticClass: "member__birth" }, [
                _vm._v(_vm._s(_vm.myData.BirthDay))
              ]),
              _c("div", { staticClass: "member__photo" }, [
                _c("div", { staticClass: "photo__inset" }, [
                  _c(
                    "div",
                    { staticClass: "photo__bg" },
                    [
                      _c(
                        "v-avatar",
                        { staticClass: "photo", attrs: { size: "168" } },
                        [_c("img", { attrs: { src: _vm.headImage } })]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "member__photo__platform" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loginMethods == "Line",
                        expression: "loginMethods == 'Line'"
                      }
                    ],
                    attrs: {
                      src: require("@/assets/歸戶平台/LINE.png"),
                      alt: ""
                    }
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loginMethods === "Facebook",
                        expression: "loginMethods === 'Facebook'"
                      }
                    ],
                    attrs: {
                      src: require("@/assets/歸戶平台/facebook.png"),
                      alt: ""
                    }
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loginMethods === "Google",
                        expression: "loginMethods === 'Google'"
                      }
                    ],
                    attrs: {
                      src: require("@/assets/歸戶平台/GOOGLE.png"),
                      alt: ""
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "member__space" }),
              _c("div", { staticClass: "member__name" }, [
                _c("div", { staticClass: "member__name__text" }, [
                  _vm._v(_vm._s(_vm.myData.Name))
                ]),
                _c("div", { staticClass: "member__name__uid" }, [
                  _vm._v("\n          ID：" + _vm._s(_vm.UID) + "\n          "),
                  _c("img", {
                    attrs: { src: require("@/assets/icon-copy.svg") },
                    on: {
                      click: function($event) {
                        return _vm.copyClipboard(_vm.UID)
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "member__info" }, [
                _c("div", { staticClass: "info__list" }, [
                  _c("div", { staticClass: "info__item" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/icon_gender.svg") }
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.myData.GenderDisplay))])
                  ]),
                  _c("div", { staticClass: "info__item" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/icon_location.svg") }
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.myData.City))])
                  ]),
                  _c("div", { staticClass: "info__item" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/icon_time.svg") }
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.myData.BirthTime))])
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass: "info__edit",
                    on: {
                      click: function($event) {
                        return _vm.toMemberEdit()
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "ifNotActive",
                      attrs: { src: require("@/assets/icon_edit.svg") }
                    }),
                    _c("img", {
                      staticClass: "ifActive",
                      attrs: { src: require("@/assets/icon_edit_active.svg") }
                    }),
                    _vm._v("\n          編輯\n        ")
                  ]
                )
              ])
            ])
          ]),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.showEdit
              ? _c("div", { staticClass: "toast" }, [_vm._v("該筆資料已修改")])
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header custom-container" }, [
      _c("img", {
        staticClass: "img-title",
        attrs: { src: require("@/assets/header_member.svg"), alt: "" }
      }),
      _c("span", [_vm._v("個人檔案")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }